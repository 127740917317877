html {
	padding: 0;
	margin: 0;
	height: 100%;
	font-family: Arial, Helvetica, sans-serif;  
	
}



 body {
	padding: 0;
	margin: 0;
	height: 100%;
	font-family: Arial, Helvetica, sans-serif;  
	
	
}
.header {
	position: fixed;
	height: 177px;
	top: 0;
	left: 0;
	right: 0;
	background-color: #FFF;
	z-index:2
}
.footer {
	text-align:center;
	height: auto;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #0d709f;	
	display:block;
	
}
.content {
	
	padding: 188px 0 50px 0;
	background-color: #0d709f;	
	

	height: auto;
	
}


@media screen and (max-width: 1000px) {

.header {
	
	height: auto;
	position: relative;
	
	top: 0;
	left: 0;
	right: 0;
	background-color: #FFF;
	z-index:2
}

.content {
	position: relative;
	padding: 0px 0 50px 0;
	background-color: #0d709f;	
	height: auto;
	
}


}